.content {
	width: 100%;
	max-width: 90px;
	height: 100%;
	max-height: 120px;
	position: relative;
	border: var(--space-2xs) solid var(--primary-white);

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.root {
	--card-radius: 4px;

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--space-sm);
	cursor: pointer;

	&:has(:focus-visible) {
		outline: 2px solid black;
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: 3px;
		z-index: 1;
	}

	& .cardBody {
		background-color: var(--primary-grey);
		width: 100%;
		position: relative;
		border-radius: var(--card-radius);

		& > img {
			width: 100%;
			height: auto;
			aspect-ratio: 4 / 5;
			border-radius: var(--card-radius);
			object-fit: cover;
		}
	}

	& .overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		inset: 0;
		z-index: 20;
	}

	& .cardTag {
		--tag-font-size: 9px;

		display: flex;
		align-items: center;
		justify-content: center;
		font-size: var(--tag-font-size);

		@media (--md) {
			--tag-font-size: 10px;
		}
	}

	& .cardVariants {
		width: fit-content;
		margin-top: auto;
		display: none;
		align-items: center;
		gap: 2px;
		z-index: 999;

		@media (--md) {
			display: flex;
		}
	}

	& .cardFooter {
		width: 100%;
		padding: 0 5px;
		display: grid;
		grid-template-areas: "footerInfo favourite";
		grid-template-columns: 1fr auto;

		@media (--md) {
			padding: 0 var(--space-sm);
		}

		& .footerInfo {
			grid-area: footerInfo;
			display: flex;
			flex-direction: column;
			gap: var(--space-2xs);
		}

		& .favourite {
			width: 100%;
			height: fit-content;
			margin-left: auto;
			margin-right: 0;
			grid-area: favourite;
		}
	}

	& .variantAmount {
		margin-top: 0;
		display: block;
		color: var(--primary-dark-blue-06);

		@media (--md) {
			display: none;
		}
	}

	@media (hover: hover) {
		&:hover {
			& .favourite {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.productName,
.productPrice {
	margin-top: 0;
	line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	font-size: 11px;

	@media (--md) {
		font-size: 15px;
	}
}

.priceBeforeDiscount {
	position: absolute;
	margin-left: var(--space-2xs);
	color: var(--primary-dark-blue-04);
	text-decoration: line-through;

	@media (--lg) {
		margin-left: var(--space-xs);
	}
}

.variantImg {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: border-color 0.2s ease;
	color: black;
	text-decoration: none;
	border: 1px solid var(--primary-dark-blue-01);
	border-radius: 4px;
	background-color: var(--product-card-background);
	/* stylelint-disable-next-line no-descending-specificity */

	@media (hover: hover) {
		&.borderBlack,
		&:hover {
			border-color: black;
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	& img {
		border-radius: 4px;
		object-fit: contain;
	}

	&.small {
		width: 32px;
		height: 40px;

		& > img {
			width: 24px;
			height: 30px;
			object-fit: contain;
		}
	}

	&.large {
		width: 53px;
		height: 66px;
		padding: 7px 6px;

		& > img {
			width: 42px;
			height: 52px;
		}

		@media (--md) {
			width: 56px;
			height: 70px;
			padding: 8px 7px;
		}
	}
}

.skeleton {
	& .image {
		width: 100%;
		aspect-ratio: 4 / 5;
		flex: 1;
	}
}
